<template>
  <div class="common-two-col-text-block" ref="block">
    <div
      class="common-two-col-text-block__wrap"
      :class="{
        '--centered-on-mobile': centeredOnMobile,
        '--small-width': props.item?.titleAsSimpleText,
      }"
    >
      <div class="common-two-col-text-block__col">
        <template v-if="props.item?.titleAsSimpleText">
          <div class="common-two-col-text-block__text-wrap">
            <div
              class="common-two-col-text-block__text"
              v-html="props.item?.title"
            />
          </div>
        </template>
        <template v-else>
          <div
            class="common-two-col-text-block__title-wrap"
            :class="{ '--smaller-title': props.smallerTitle }"
          >
            <div
              class="common-two-col-text-block__title"
              :class="{
                'title-l-4': !props.smallerTitle,
                'text-xl-1': props.smallerTitle,
              }"
              v-html="props.item?.title"
            />
          </div>
        </template>
      </div>
      <!-- Content -->
      <div class="common-two-col-text-block__col">
        <div class="common-two-col-text-block__text-wrap">
          <div
            class="common-two-col-text-block__text"
            v-html="props.item?.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonGeneralGridBlockText',
}
</script>

<script setup>
const props = defineProps({
  item: Object,
  smallerTitle: {
    type: Boolean,
    default: false,
  },
  centeredOnMobile: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
